function language(state = 'en', {type, payload}) {
    switch (type) {
        case 'CHANGE_LANG':
            return payload;

        default:
            return state;
    }
}

export default language;
