import {applyMiddleware, createStore, combineReducers} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import { createBrowserHistory as createHistory } from 'history'
import {routerMiddleware} from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import languageMiddleware from 'redux/middleware/language'

import language from './reducers/language';

export const history = createHistory({});

const rootReducer = combineReducers({
    language,
});

const middleware = [
    routerMiddleware(history),
    thunkMiddleware,
    languageMiddleware,
];

const enhancer = composeWithDevTools(applyMiddleware(...middleware));

let languageState = null;

try {
    languageState = JSON.parse(localStorage.getItem('language'));
} catch (err) {
    //console.log(err);
}

const persistedState = languageState ? {language: languageState} : {};

const store = createStore(
    rootReducer,
    persistedState,
    enhancer
);

export default store;
