import styled from 'styled-components';

export const BlockWrapper = styled.div`
/*min-width: 1060px;*/
padding: 50px 0;
background: ${props => props.background ? props.background : ''};
`;

export const BgFixedFix = styled.div`
overflow: hidden;
width: 100vw;
height: 100vh;
position: fixed;
top: 0;
left: 0;
z-index: -10;
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
background-attachment: local;
background-position: center;
background-repeat: no-repeat;
background-image: linear-gradient(to bottom, ${props => props.background}, ${props => props.background}),url(${props => props.backgroundImg ? props.backgroundImg : ''});
background-color: ${props => props.background ? props.background : ''};
`;

export const Container = styled.div`
min-width: 320px;
max-width: 1367px;
margin: 0 auto;

/*display: flex;
flex-direction: ${props => props.direction ? props.direction : 'row'}
justify-content: center;
align-items: center;
flex-wrap: ${props => props.wrap ? props.wrap : 'nowrap'};
padding: 0 15px;*/

@media (min-width: 768px) {
padding: 0;
justify-content: space-evenly;
}
`;
