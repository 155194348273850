import React from 'react';
import {Title, Text} from '../Text';
import Counter from '../Counter';
import MapNew from '../MapNew';
import styled from 'styled-components';

const car = require('./teslaX@2x.png');

const Wrapper = styled.div`
width: 100%;
height: 100%;
overflow: hidden;
margin-bottom: 50px;

@media (max-width: 1023px) {
margin-bottom: 0;
margin-top: 75px;
height: auto;
}
`;
const Container = styled.div`
max-width: 1440px;
margin: 0 auto;

position: relative;
height: 100%;
padding: 100px 0 200px 32px;
display: flex;
justify-content: space-between;

@media (max-width: 1023px) {
padding: 18px 0 60px 9px;
height: auto;
flex-wrap: wrap;
}

@media (max-width: 550px) {
padding: 18px 0 170px 9px;
margin-bottom: 60px;
}
`;

const Content = styled.div`
flex-basis: 35%;
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: flex-start;
background: white;

@media (max-width: 1500px) {
justify-content: center;
}

@media (max-width: 1200px) {
justify-content: flex-start;
}

@media (max-width: 1023px) {
flex-basis: 100%;
flex-direction: row;
}

`;

const ContentWithBg = styled.div`
position: relative;
min-height: 535px;
flex-basis: 38%;
padding: 29px 40px 50px 0;
display: flex;
justify-content: space-between;
align-items: flex-start;
background-color: rgb(255, 174, 0);

@media (max-width: 1023px) {
flex-basis: 60%;
padding: 29px 9px 50px;
flex-direction: column;
min-height: calc(430px + 29px + 50px);
}

@media (max-width: 550px) {
min-height: calc(215px + 9px + 46px);
padding: 9px 9px 46px;
}
`;

const TextBx = styled.div`
& > p {
margin-top: 15px;
}

@media (max-width: 1023px) {
flex-basis: 60%;
}

@media (max-width: 550px) {
flex-basis: 100%;
margin-bottom: 40px;
padding-right: 9px;
}

`;

const ImgBx = styled.div`
flex-basis: 27%;
position: relative;

& > img {
position: absolute;
z-index: 2;
width: 650px;
height: auto;
bottom: -15%;
transform: translateX(-22%);
}

@media (max-width: 1300px) {
& > img {
transform: translateX(-15%);
}
}

@media (max-width: 1023px) {
flex-basis: 40%;
& > img {
width: auto;
height: 190px;
transform: translateX(0);
right: -50px;
bottom: -55px;
}
}

@media (max-width: 550  px) {
& > img {
height: 110px;
right: -95px;
}
}
`;

const CounterBlock = styled.div`
width: 85%;
display: flex;
margin-top: 25px;

@media (max-width: 1023px) {
flex-direction: column;
width: 30%;
align-items: flex-end;
margin-top: 0;
& > div {
padding: 0 0 20px 0;
min-width: 150px;
}
}

@media (max-width: 550px) {
flex-direction: row;
position: absolute;
bottom: 0;
width: calc(100vw - 18px);
align-items: flex-start;
justify-content: space-between;
& > div {
padding: 0;
width: 25%;
min-width: 100px;
text-align: center;
}
}
`;

const ContainerMap = styled.div`
background: black;
max-height: 85%;
width: 145%;
position: absolute;
z-index: 1;
right: 27px;

& > div {
height: 429px;
width: 100%;
}

@media (max-width: 1023px) {
right: 9px;
width: calc(100vw - 18px);
& > div {
height: 430px;
width: calc(100vw - 18px);
}
}

@media (max-width: 550px) {


& > div {
height: 215px!important;

& > div  {
height: 215px!important;
}
}
}
`;

const chargingStationsInfo = [
  {
    title: 'live',
    text: 'Діючих станцій',
    number: 17,
    id: 'live',
  },
  {
    title: 'now building',
    text: 'Зараз будується',
    number: 3,
    id: 'building',
  },
  {
    title: 'the plan',
    text: 'Планується',
    number: 20,
    id: 'plan',
  },
];

const SectionMap = () => {

  const counters = chargingStationsInfo.map(el => <Counter key={el.id} title={el.title} text={el.text} end={el.number}/>);
  return (
    <Wrapper id='#map'>
      <Container>

        <Content>
          <TextBx>
            <Title>
              Локації зарядних станцій
            </Title>
            <Text>
              Найбільш комфортний та швидкий спосіб зарядки Вашого електромобіля – це використання мобільного додатку та
              станцій швидкої зарядки.
            </Text>
          </TextBx>

          <CounterBlock>
            {
              counters
            }
          </CounterBlock>
        </Content>

        <ImgBx>
          <img src={car} alt="car"/>
        </ImgBx>

        <ContentWithBg>
          <ContainerMap>
            <MapNew/>
          </ContainerMap>
        </ContentWithBg>

      </Container>
    </Wrapper>
  )
};

export default SectionMap
