const step_1 = require('./1.png');
const step_1_2 = require('./1-2.png');
const step_2 = require('./2.png');
const step_2_2 = require('./2-2.png');
const step_3 = require('./3.png');
const step_3_2 = require('./3-2.png');
const step_3_3 = require('./3-3.png');
const step_4 = require('./4.png');
const step_4_2 = require('./4-2.png');
const step_5 = require('./5.png');
const step_5_2 = require('./5-2.png');
const step_5_3 = require('./5-3.png');
const step_5_4 = require('./5-4.png');
const step_6 = require('./6.png');
const step_6_2 = require('./6-2.png');
const step_6_3 = require('./6-3.png');
const step_6_4 = require('./6-4.png');
const step_7 = require('./7.png');
const step_7_2 = require('./7-2.png');
const step_7_3 = require('./7-3.png');
const step_7_4 = require('./7-4.png');

export const StepsList = {
  1: 'РЕЄСТРАЦІЯ',
  2: 'ВИБІР ЛОКАЦІЇ',
  3: 'ПОЧАТОК ЗАРЯДКИ',
  4: 'ЗАРЯДКА',
  5: 'Додавання RFID',
  6: 'Бронювання',
  7: 'ПОПОВНЕННЯ БАЛАНСУ'
};

export const StepsDetailsList = {
  1: [
    '1.1 Введіть номер телефону',
    '1.2 Дочекайтеся отримання SMS повідомлення з кодом підтвердження і введіть його у відповідне поле. Ознайомтесь та прийміть угоду із обслуговування',
  ],
  2: [
    '2.1 Оберіть зручну для Вас локацію з наявних на мапі',
    '2.2 Оберіть тип коннектору.\n' +
    'Можливі стани індикації коннектору:\n' +
    '*зелений - вільний;\n' +
    '*синій -  зайнятий;\n' +
    '*бірюзовий -  зарезервований;\n' +
    '*червоний - несправний\n'
  ],
  3: [
    '3.1 Натисніть на необхідний коннектор в додатку',
    '3.2 Переконайтеся що ви перебуваєте біля потрібної вам станції та натисніть "Старт"',
    '3.3 Дочекатися закінчення авторизації',
  ],
  4: [
    '4.1 Виконуйте інструкції наведені на екрані Вашого смартфону',
    '4.2 У разі наявності заздалегідь активованої RFID картки піднесіть її до зчітувача та дочекайтеся авторизації на екрані станції. Після вдалої авторизації виберіть необхідний тип коннектору на екрані станції та приєднайте електромобіль. Після цього натисніть кнопку «Старт» на екрані станції',
  ],
  5: [
    '5.1. Використовуючи карту з технологією NFC (будьяка банківська карта з функцією PayPass) можна авторизувати початок зарядки на станції без мобільного додатку.\n' +
    'Щоб активувати таку функцію виберіть пункт "RFID" в нижньому меню та дотрімуйтесь інструкцій',
    '5.2. Додаток запропонує список станцій і відстань до них з огляду на ваше місце розташування. Оберіть станцію біля якої Ви безпосередньо знаходитесь\n',
    '5.3. Після вибору станції у вас буде 1 хв. щоб піднести картку з NFC міткою до считувача',
    '5.4. Зчитування супроводжуватиметься звуковим сигналом станції та повідомленням у мобільному додатку, де необхідно буде натиснути "Зберегти"',
  ],
  6: [
    '6.1 Оберіть локацію та вільний коннектор',
    '6.2 Натисніть на відкритий «замок» праворуч від необхідного коннектору. Оберіть час очікування',
    '6.3 Після вибору «замок» перейде в стан закритий під яким відобразиться таймер зворотнього відліку до завершення бронювання',
    '6.4 Скасування бронювання можна здійснити натисканням на значок «замок» і підтвердженням умов скасування',
  ],
  7: [
    '7.1 Поповнення балансу можна здійснити в пункту профілю «Баланс»',
    '7.2 Пункт «Баланс» дозволяє переглядати поточний стан та історії платежів.\n' +
    'Натиснувши кнопку «поповнити» ви перейдете до способів оплати\n',
    '7.3 При першому поповненні балансу необхідно додати спосіб оплати. Надалі картки які були використані раніше будуть відображатися в цьому вікні.',
    '7.4 Щоб завершити оплату виконуйте інструкції на екрані Вашого смартфону.',
  ],
};

export const photoMap = {
  1: {
    '1': step_1,
    '2': step_1_2,
  },
  2: {
    '1': step_2,
    '2': step_2_2,
  },
  3: {
    '1': step_3,
    '2': step_3_2,
    '3': step_3_3,
  },
  4: {
    '1': step_4,
    '2': step_4_2,
  },
  5: {
    '1': step_5,
    '2': step_5_2,
    '3': step_5_3,
    '4': step_5_4,
  },
  6: {
    '1': step_6,
    '2': step_6_2,
    '3': step_6_3,
    '4': step_6_4,
  },
  7: {
    '1': step_7,
    '2': step_7_2,
    '3': step_7_3,
    '4': step_7_4,
  },
};
