import React from 'react'
import styled from 'styled-components';
import {Title, Text, SecondaryText} from '../Text'

const car = require('./jaguarIpace.png');
const icon_car = require('./car.svg');
const icon_client = require('./client.svg');
const icon_location = require('./location.svg');
const icon_phone = require('./phone.svg');

const Wrapper = styled.div`
width: 100%;
min-height: calc(685px + 150px);
overflow: hidden;
margin-top: 50px; 

@media (max-width: 1023px) {
min-height: auto;
margin-top: 0; 
}
`;
const Container = styled.div`
max-width: 1440px;
margin: 0 auto;

padding: 31px 24px 31px;
display: flex;

@media (max-width: 1023px) {
padding: 31px 9px 10px;
align-items: flex-start;
}
@media (max-width: 767px) {
padding: 31px 9px 4px;
}
`;

const ContentWithBg = styled.div`
position: relative;
min-height: 685px;
flex-basis: 40%;
padding: 55px 30px;
display: flex;
justify-content: space-between;
align-items: flex-start;
background-color: rgb(255, 174, 0);

& > p {
width: 33%;
color: #FAF8EC;
text-align: left;
}

@media (max-width: 1023px) {
min-width: 357px;
padding: 85px 35px 195px;
flex-direction: column;
min-height: auto;
margin-bottom: 70px;
& > p {
width: 100%;
}
& > p:first-child {
margin-bottom: 55px;
}
}

@media (max-width: 767px) {
flex-basis: 50%;
min-width: 50%;
padding: 85px 35px 155px;
}

@media (max-width: 550px) {
flex-basis: 100%;
min-width: 100%;
padding: 60px 12px 220px;
}
`;

const Content = styled.div`
flex-basis: 66%;
display: flex;
flex-direction: column;
padding: 55px 36px;

@media (max-width: 1023px) {
padding: 0px 17px;
}
@media (max-width: 550px) {
display: none;
}
`;

const CarImg = styled.img`
width: 110%;
height: auto;
position: absolute;
right: -30%;
bottom: -15%;

@media (max-width: 1200px) {
bottom: -70px;
}
@media (max-width: 1150px) {
bottom: -30px;
}
@media (max-width: 1023px) {
width: 80%;
min-width: 290px;
right: -20%;
bottom: -75px;
}
@media (max-width: 768px) {
bottom: -100px;
}
@media (max-width: 550px) {
width: 350px;
right: -120px;
}
`;

const SubTitle = styled(Text)`

margin: 19px 0 43px;
max-width: 70%;

@media (max-width: 1023px) {
max-width: 100%;
}
@media (max-width: 676px) {
margin: 10px 0 0;
}
`;

const BoxCards = styled.div`
width: 80%;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
align-content: space-between;
align-self: flex-end;

@media (max-width: 883px) {
display: none;
}
`;

const BoxCardsTabletAndMobile = styled.div`
display: none;

@media (max-width: 883px) {
width: 100%;
display: flex;
flex-wrap: wrap;
justify-content: space-evenly;
align-content: space-between;
align-self: flex-end;
}

@media (max-width: 550px) {
padding: 0 9px;
flex-wrap: wrap
}
`;

const MobileConteinerTitle = styled.div`
display: none;

@media (max-width: 550px) {
padding: 0 9px;
display: flex;
flex-direction: column;
}
`;

const Card = styled.div`
width: 46%;
margin-top: 50px;
display: flex;
align-items: flex-start;

& > img {
width: 35px;
height: auto;
margin: 6px;
}

@media (max-width: 883px) {
margin-top: 55px;
}
@media (max-width: 550px) {
width: 100%;
margin-top: 38px;
}
`;


const iconList = [icon_client, icon_car, icon_location, icon_phone];
const textList = [
    'Висока якість сервісу і увага до кожного клієнта',
    'Найефективніщі локації розміщення станцій',
    'Потужні та якісні зарядні станції провідних європейських виробників',
    'Зручний мобільний додаток для користувачів (iOS і Android)',
];

const SectionAboutUs = () => {

    let renderCards = () => {
        return textList.map((el, i) => (
            <Card key={i}>
                <img src={iconList[i]} alt="icon"/>
                <SecondaryText>{el}</SecondaryText>
            </Card>
        ))
    };

    let renderTitle = [
        <Title key={'Title'}>
            Мережа зарядних станцій QuickPower
        </Title>,
        <SubTitle key={'SubTitle'}>Ми дбаємо про ваш комфорт та щодня піклуємось, щоб користування електромобілем, було для
            вас максимально комфортним та зручним!
        </SubTitle>
    ];


    return (
        <Wrapper id='#aboutUs'>
            <MobileConteinerTitle>
                {renderTitle}
            </MobileConteinerTitle>
            <Container>
                <ContentWithBg>
                    <Text>
                        Активне зростання ринку електромобілів в Україні в продовж 2016-2019 років стало яскраво
                        вираженим трендом.
                    </Text>
                    <Text>
                        Мережа QuickPower почала свою діяльність в квітні 2019 року із запуску станцій швидкісної
                        зарядки.
                    </Text>
                    <CarImg src={car} alt="electric car"/>
                </ContentWithBg>
                <Content>
                    {renderTitle}
                    <BoxCards>
                        {renderCards()}
                    </BoxCards>
                </Content>
            </Container>
            <BoxCardsTabletAndMobile>
                {renderCards()}
            </BoxCardsTabletAndMobile>
        </Wrapper>
    )
};

export default SectionAboutUs
