import React, {Component} from 'react'
import styled from 'styled-components'
import {BlockWrapper, Container} from "components/BlockWrapper"

const TitlePage = styled.span`
  font-family: "Roboto", sans-serif;
  color: #000000;
  font-size: 30px;
  margin-bottom: 25px;
  display: block;
  width: 100%;
  text-align: center;

  @media (max-width: 550px) {
    font-size: 25px;
  }
`

const TextBlackPage = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 400;
  text-align: justify;

  @media (max-width: 550px) {
    font-size: 13px;
  }
`

const SpecificationTextPage = styled.p`
  font-family: "Roboto", sans-serif;
  color: #000000;
  font-size: 22px;
  text-align: left;
  font-weight: 700;
  margin: 10px 0;

  @media (max-width: 550px) {
    font-size: 18px;
  }
`

class TermsAndConditionsPage extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      //behavior: "smooth"
    })
  }

  render() {
    return (
      <BlockWrapper style={{borderTop: '1px solid #e0dede'}}>
        <Container style={{justifyContent: 'start', maxWidth: 940}} wrap={'wrap'}>
          <TextBlackPage>
            Ви погоджуєтеся з тим, що цей ПУБЛІЧНИЙ ДОГОВІР набирає чинності для всіх споживачів з 01 червня 2019 року, та зобов'язує
            переглянути його умови.
          </TextBlackPage>
          <TextBlackPage>
            Використання мережі QUICKPOWER підтверджує, що ви приймаєте всі умови цієї Угоди, умови вартості послуг і умови використання
            зарядних пристроїв QUICKPOWER.
          </TextBlackPage>
          <TitlePage>
            ПУБЛІЧНИЙ ДОГОВІР
          </TitlePage>

          <SpecificationTextPage>
            1. УМОВИ ТА ВИЗНАЧЕННЯ ДОГОВОРУ
          </SpecificationTextPage>
          <TextBlackPage>
            1.1 Ці Загальні положення та умови застосовуються до всіх Продуктів і Послуг, які QUICKPOWER пропонує та надає Споживачам. Там,
            де зазначено, конкретні положення та умови застосовуються до певних типів Продуктів і/або Послуг.<br/>
            1.2 Ці Загальні положення та умови не застосовуються до продуктів та/або послуг, які не пропонує та не надає QUICKPOWER, а третя
            сторона, наприклад торговельний посередник, у цьому випадку угода купівлі-продажу укладається з третьою стороною, а не з
            QUICKPOWER.<br/>
            1.3. Платіжна картка: платіжна картка RFID, надана компанією QUICKPOWER, яка дає доступ до використання одного або кількох
            пунктів заряджання.<br/>
            1.4 Пункт заряджання: об’єкт або об’єкти, де можна заряджати електричний автомобіль за допомогою зарядної картки або мобільного
            додатка QUICKPOWER, де кінцевий користувач може вмикати та вимикати живлення.<br/>
            1.5 Послуги зарядки: усі послуги, які QUICKPOWER пропонує Клієнту з використанням Платіжної картки або мобільного додатку
            QuickPower.<br/>
            Послуга – Послуга Виконавця із зарядки акумуляторних батарей електромобіля Замовника на зарядних станціях Виконавця, яка
            відбувається в автоматичному режимі без залучення персоналу Виконавця.<br/>
            1.6 Плата - розмір вартості Послуг, який встановлюється Виконавцем для Клієнта за отримання Клієнтом Послуг.<br/>
            1.7 Електричний транспортний засіб: дорожній транспортний засіб з більш ніж двома колесами, який повністю або частково
            приводиться в рух електричним двигуном, цей транспортний засіб використовує або не використовує електроенергію, що зберігається
            в акумуляторі, який заряджається за допомогою зарядної точки.<br/>
            1.8 Мережа: мережа QUICKPOWER і партнерська мережа з пунктів заряджання.<br/>
            1.9 Партнерська мережа: сукупність усіх зарядних пунктів, які можна використовувати за допомогою платіжної картки або мобільного
            додатка QuickPower і розташованих за межами мережі QUICKPOWER.<br/>
            1.10 Портал: веб-сайт www.quickpower.net, де власник зарядної точки може увійти, використовуючи дані для входу, отримані від
            QUICKPOWER як власник зарядної точки, наприклад, щоб керувати даними про використання та переглядати споживання.<br/>
            1.11 Приватна зарядна точка: зарядна точка, що надається Клієнту та встановлюється, розміщується, підтримується та/або керується
            мережею QUICKPOWER згідно з Контрактом, яку можна використовувати за допомогою платіжної картки або мобільних додатків
            QuickPower для публічного заряджання.<br/>
            1.12 Продукт: фізичний продукт, включаючи точку заряджання, який надається або буде надано Клієнту компанією QUICKPOWER.<br/>
            1.13 Загальнодоступна зарядна точка: зарядна точка в Мережі QUICKPOWER, яка відкрита для використання усіма клієнтами з
            платіжною карткою, активованою для цієї мети, або мобільним додатком QuickPower.<br/>
            1.14 Веб-сайт: загальнодоступний веб-сайт www.quickpower.net<br/>

          </TextBlackPage>

          <SpecificationTextPage>
            2. Укладення договору
          </SpecificationTextPage>
          <TextBlackPage>
            2.1 Договір між QUICKPOWER і користувачем укладається шляхом реєстрації в мобільному додатку QuickPower і прийняття
            застосовності та дійсності цих Загальних положень та умов.<br/>
            2.2 Ці Положення та умови адаптовано відповідно до постійного вдосконалення наших послуг. Про зміни на нашому веб-сайті буде
            оголошено завчасно до їх впровадження. Тому вам слід регулярно переглядати цей контракт, щоб отримати останню версію.<br/>
            Термін дії договору необмежений і може бути розірваний з попередженням до кінця місяця. Розірвання замовником може бути
            здійснено письмово або електронною поштою quickpowerua@gmail.com і не вимагає пояснення причин.<br/>
          </TextBlackPage>

          <SpecificationTextPage>
            3. Тарифікація послуг і ціни
          </SpecificationTextPage>
          <TextBlackPage>
            Поповнення Рахунку Клієнта здійснюється виключно з метою подальшого отримання Послуг на суму поповнення балансу.<br/>
            3.1 Поповнення рахунку клієнта в мобільному додатку QUICKPOWER за допомогою карток міжнародних платіжних систем Visa /
            MasterCard.<br/>
            &nbsp;&nbsp;3.1.1. Поповнення рахунку клієнта здійснюється через Мобільний додаток QUICKPOWER шляхом переказу коштів з
            клієнтської картки Visa/MasterCard на баланс Мобільного додатку.<br/>
            &nbsp;&nbsp;3.1.2. QUICKPOWER не зберігає інформацію про клієнтські картки. Уся ця інформація зберігається та надається третіми
            партнерами з сертифікатом PCI DSS рівня 1.<br/>
            &nbsp;&nbsp;3.1.3. Поповнення Рахунку Клієнта здійснюється за фактичною вартістю Послуг, яка відображається в Мобільному додатку
            у відповідному розділі.<br/>
            &nbsp;&nbsp;3.1.4. Результатом поповнення Рахунку Клієнта є збільшення обсягу передплачених Послуг на рахунку Клієнта, яке
            відбувається відразу після отримання від платіжної системи підтвердження про успішне поповнення коштів з зареєстрованого
            рахунку.<br/>
            3.2 Для початку нарахування клієнту необхідно поповнити власний баланс у мобільному додатку на будь-яку суму, що перевищує нуль.
            Коли баланс клієнта досягає нуля, послуги з тарифікації не надаються.<br/>
            &nbsp;&nbsp;3.2.1. Під час нарахування клієнтського балансу в мобільному додатку зменшуйте на кількість кВт/год електромобіля,
            зарядженого зарядним пунктом<br/>
            &nbsp;&nbsp;3.2.2. Якщо стягнення плати через точку заряду було перервано, невикористані кошти будуть збережені на балансі
            клієнтського рахунку<br/>

          </TextBlackPage>

          <SpecificationTextPage>
            4. Повернення/відшкодування
          </SpecificationTextPage>
          <TextBlackPage>
            4.1 Якщо ви не плануєте використовувати свій обліковий запис і служби мережі QUICKPOWER, ви можете видалити всі свої особисті
            дані, увійшовши на своєму мобільному телефоні в програму QuickPower, Profile і натиснувши червону кнопку «Видалити обліковий
            запис». Після підтвердження та видалення протягом 7 робочих днів наша служба підтримки зв’яжеться з вами та поверне
            невикористану суму з вашого балансу на Mastercard/Visa, яку ви використовували для поповнення балансу.<br/>
            4.2 Якщо у вас виникли проблеми під час онлайн-оплати в мобільному додатку QuickPower, ви можете зателефонувати в службу
            підтримки за тел. +380931467524 Україна/Київ.<br/>
            4.3 Підключити платіжну картку до свого мобільного рахунку. Ви не можете передавати її третім особам, у разі втрати платіжної
            картки користувач повинен негайно повідомити QUICKPOWER електронною поштою ( quickpowerua@gmail.com ) або за телефоном
            +380674444778.<br/>
          </TextBlackPage>

          <SpecificationTextPage>
            5. Експлуатація та використання
          </SpecificationTextPage>
          <TextBlackPage>
            5.1 Для реєстрації в мобільному додатку необхідно ввести свій номер телефону, отримати текстове повідомлення з кодом, прочитати
            та прийняти Правила та умови.<br/>
            5.2 Для початку першої зарядки необхідно поповнити баланс більше нуля. Для цього зайдіть в меню Баланс / Поповнити і
            скористайтеся своєю банківською карткою.<br/>
            5.3 Сеанс зарядки<br/>
            &nbsp;&nbsp;5.3.1. Зареєстрований клієнт вставляє в свій електромобіль роз'єм кабелю потрібного порту.<br/>
            Якщо зарядний роз’єм, необхідний Клієнту, вже вставлено в порт електромобіля іншого Клієнта, його використання дозволяється лише
            після завершення сеансу заряджання електромобіля попереднього Клієнта, який уже розпочав сеанс заряджання. Стан уже розпочатого
            сеансу заряджання можна побачити на екрані зарядної станції.<br/>
            Слід знати, що на станціях, оснащених кількома портами швидкої зарядки, CHAdeMO та CCS (Combo 2), одночасно можна
            використовувати лише один порт швидкої зарядки. У той же час використання зарядного порту змінного струму типу 2 можна
            використовувати незалежно від того, чи зайняті порти швидкої зарядки CHAdeMO або CCS.<br/>
            &nbsp;&nbsp;5.3.2. Мобільний додаток показує номер і назву зарядної станції, клієнт обирає порт зарядки, натискає
            «Зарядити».<br/>
            &nbsp;&nbsp;5.3.3. Якщо обсяг передплачених послуг на рахунку Клієнта більше нуля, починається сеанс нарахування.<br/>
            &nbsp;&nbsp;5.3.4. Клієнт запускає сеанс зарядки, натиснувши відповідну кнопку.<br/>
            &nbsp;&nbsp;5.3.5. Обсяг сеансу зарядки вимірюється в кВт/год.<br/>
            &nbsp;&nbsp;5.3.6. Клієнт може завершити сеанс зарядки наступним чином:<br/>
            &nbsp;&nbsp;&nbsp;- Дочекайтеся закінчення сеансу<br/>
            &nbsp;&nbsp;&nbsp;- Натисніть кнопку переривання сеансу на екрані «Статус заряджання» в мобільній програмі в меню «Заряджання».<br/>
            &nbsp;&nbsp;5.3.7. В екстреній ситуації натисніть червону кнопку екстреної допомоги Charging Charging Charging на передній
            панелі зарядної станції. Використання кнопки аварійного виходу сеансу заряджання дозволено лише в екстрених випадках і
            заборонено з будь-якої іншої причини.
            &nbsp;&nbsp;5.3.8. Після завершення сеансу зарядки Клієнт повинен дочекатися автоматичного розблокування порту та витягнути
            роз’єм з порту електромобіля.<br/>
            &nbsp;&nbsp;5.3.9. Зменшення суми передплачених Послуг з Клієнтського рахунку Зареєстрованого клієнта відбувається після
            закінчення Сесії нарахування.<br/>
          </TextBlackPage>

          <SpecificationTextPage>
            6. Відповідальність
          </SpecificationTextPage>
          <TextBlackPage>
            6.1 QUICKPOWER не несе відповідальності за постійну доступність програми QuickPower і веб-сайту, зокрема, за доступність
            зарядних станцій і працездатність зарядних станцій і мобільних кінцевих пристроїв.<br/>
            6.2 Це не стосується випадків заподіяння шкоди життю, тілу чи здоров'ю.<br/>
            6.3 Декларація щодо прийняття на себе відповідальності за відшкодування, повернення, коштів кінцевому споживачеві:<br/>
            Ми беремо на себе відповідальність за відшкодування, повернення коштів кінцевому споживачеві щодо платіжної служби та
            непрацюючого мобільного додатку.<br/>
            З іншого боку, про технічні проблеми із зарядною станцією слід повідомляти оператору відповідної зарядної станції. Ми не несемо
            відповідальності за це.<br/>
          </TextBlackPage>

          <SpecificationTextPage>
            7. Розпорядження про скасування
          </SpecificationTextPage>
          <TextBlackPage>
            Ви можете скасувати свою договірну угоду в текстовій формі (наприклад, лист, факс, електронна пошта) протягом 14 днів без
            вказівки причин. Термін починається з моменту отримання цієї інструкції в текстовому вигляді, але не раніше укладення договору.
            Своєчасне відправлення скасування є достатнім для дотримання терміну скасування. Відмову слід надіслати на адресу:<br/>
            <br/>
            QuickPower<br/>
            ТОВ "АЛІС КОМПАНІ"<br/>
            ЄДРПОУ 43498586<br/>
            м. Київ, вул. Нижньоюрківська, б.9, 04080<br/>
            тел.: +380931467524<br/>
            Електронна пошта: quickpowerua@gmail.com<br/>
          </TextBlackPage>

          <SpecificationTextPage>
            8. Захист даних
          </SpecificationTextPage>
          <TextBlackPage>
            8.1 QUICKPOWER або замовлені постачальники послуг збирають, обробляють і використовують дані, що стосуються користувача, для
            виконання договірних відносин відповідно до положень Федерального закону про захист даних. Персональні дані використовуються
            виключно для того, щоб інформувати клієнта про нові продукти та послуги QUICKPOWER. Дані, які не є особистими, використовуються
            для цілей дослідження ринку та громадської думки, які проводить QUICKPOWER.<br/>
            8.2 Ідентифікаційні номери користувачів надсилаються оператору зарядних станцій для активації зарядних станцій.<br/>
          </TextBlackPage>

          <SpecificationTextPage>
            9. Застосовне законодавство та юрисдикція
          </SpecificationTextPage>
          <TextBlackPage>
            Договірні відносини регулюються правом України. Виключна юрисдикція для всіх спорів.<br/>
          </TextBlackPage>

          <SpecificationTextPage>
            10. Застереження про роздільність
          </SpecificationTextPage>
          <TextBlackPage>
            Якщо деякі положення договору є недійсними, тоді договір залишається в силі. Договірні сторони зобов’язані письмово домовитися
            про заміну недійсного положення положенням, яке вони погодили б добросовісно та відповідно до правових норм, якби їм було відомо
            про недійсність положення.<br/>
            <br/>
            QuickPower<br/>
            ТОВ "АЛІС КОМПАНІ"<br/>
            ЄДРПОУ 43498586<br/>
            м. Київ, вул. Нижньоюрківська, б.9, 04080<br/>
            тел.: +380931467524<br/>
            Електронна пошта: quickpowerua@gmail.com<br/>

          </TextBlackPage>

        </Container>
      </BlockWrapper>
    )
  }
}

export default TermsAndConditionsPage
