import React from 'react'
import SectionHeader from 'components/SectionHeader'
import SectionAboutUs from 'components/SectionAboutUs'
import SectionMap from 'components/SectionMap'
//import SectionBonus from 'components/SectionBonus'
import SectionInstructionNew from 'components/SectionInstructionNew'
import SectionFooter from 'components/SectionFooter'

const Home = () => {
  return (
    <>
      <SectionHeader/>
      <main>
        <SectionAboutUs/>
        <SectionMap/>
        {/*<SectionBonus/>*/}
        <SectionInstructionNew/>
      </main>
      <SectionFooter/>
    </>
  )
};

export default Home
