import styled from 'styled-components'

export const Title = styled.span`
color: #231F20;
font-size: 77px;
line-height: 1.013;
font-family: 'Bebas';
font-weight: 400;
text-transform: uppercase;

@media (max-width: 1023px) {
font-size: 60px;
line-height: 1;
}

@media (max-width: 767px) {
font-size: 40px;
}
`;

export const Text = styled.p`
font-family: "Roboto", sans-serif;
color: #1D1D1D;
font-size: 15px;
font-weight: 600;
line-height: 1.533;
`;

export const SecondaryText = styled(Text)`
font-size: 13px !important;
color: #515151;
line-height: 1.615;
font-weight: 400;
`;
